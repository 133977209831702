import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"

import Logo from "../images/logo.svg"
import Man from "../images/man-404.svg"
import Glasses from "../images/glasses.svg"

import sidebarStyle from "../styles/sidebar.module.scss"
import notfoundStyle from "../styles/notfound.module.scss"

const NotFound = (props) => {
    return (
        <div className={classNames(notfoundStyle.root, "root")}>
            <div className="sidebar">
                <div className={sidebarStyle.root}>
                    <div className={sidebarStyle.logoContainer}>
                        <Link to="/">
                            <img src={Logo} alt="quipped" className={sidebarStyle.logo} />
                            <img
                                src={Man}
                                alt="man"
                                className={sidebarStyle.man}
                                style={{
                                    transform: 'translate(1.5rem, -1rem)',
                                    opacity:  1,
                                    display: 'block',
                                }}
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <div className={notfoundStyle.container}>
                <div className={classNames(notfoundStyle.heading)}>
                無
                </div>

                <div className={classNames(notfoundStyle.text, "text-size")}>
                404 ｜　お探しのページは見つかりませんでした。
                </div>

                <Link to="/" className={classNames(notfoundStyle.text, notfoundStyle.link, "text-size")}>return to the homepage</Link>

                <div className={notfoundStyle.img}>
                    <img
                        src={Glasses}
                        alt="glasses"
                        className={notfoundStyle.glasses}
                    />
                </div>
            </div>
        </div>
    )
}

export default NotFound
